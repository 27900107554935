import React, { useContext, useMemo } from 'react'
import { useMachine } from 'react-robot'
import { SendFunction, Service } from 'robot3'
import { machine, machineContext } from '../components/SelfScheduling/machine'

interface ssmCurrent {
  current: typeof machine['state'] & {
    context: machineContext
    service?: Service<typeof machine>
  }
  send: SendFunction
}

const SelfScheduleMachineContext = React.createContext<ssmCurrent>(undefined)
export const SelfScheduleMachineProvider = (props) => {
  const [current, send] = useMachine(machine)

  const value = useMemo(() => ({ current, send }), [current, send])
  return <SelfScheduleMachineContext.Provider value={value}>{props.children}</SelfScheduleMachineContext.Provider>
}

export const useSelfScheduleMachine = () => {
  const context = useContext(SelfScheduleMachineContext)
  if (!context) {
    throw new Error('useSelfScheduleMachine must be used within a SelfScheduleMachineProvider')
  }
  return context
}
