import { DateTime } from 'luxon'
import * as React from 'react'
import { useSegmentContext, SegmentContext } from './SegmentProvider'

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  children: Function | React.ReactNode
  // eslint-disable-next-line @typescript-eslint/ban-types
  eventProperties?: object | Function
}

export function useSegment(eventProperties: object = {}) {
  const { segmentClient, eventProperties: inheritedProperties } = useSegmentContext()
  return React.useMemo(() => {
    function logEvent(eventType, eventPropertiesIn: object = {}, callback?: any) {
      if (!segmentClient) {
        return
      }

      let computed = inheritedProperties
      if (typeof eventProperties === 'function') {
        computed = eventProperties(computed)
      } else {
        computed = { ...computed, ...(eventProperties || {}) }
      }
      if (typeof eventPropertiesIn === 'function') {
        computed = eventPropertiesIn(computed)
      } else {
        computed = { ...computed, ...(eventPropertiesIn || {}) }
      }

      // event_time
      computed = { ...computed, event_time: DateTime.now().toSeconds() }

      segmentClient.track(eventType, computed, callback)
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    function instrument<T extends Function>(eventType: string, func: T): T {
      function fn(...params: any) {
        const retVal = func ? func(...params) : undefined
        logEvent(eventType)
        return retVal
      }
      return fn as any
    }

    return {
      logEvent: logEvent,
      instrument: instrument,
      eventProperties: inheritedProperties,
      segmentClient: segmentClient,
    }
  }, [eventProperties, inheritedProperties, segmentClient])
}

export function Segment(props: Props) {
  const { logEvent, instrument, eventProperties, segmentClient } = useSegment(undefined)

  // If we're not providing any additional properties, just get out of the way and call the component
  if (!eventProperties) {
    return typeof props.children === 'function' ? props.children({ logEvent, instrument }) : props.children || null
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const value = React.useMemo(
    () => ({
      eventProperties: { ...eventProperties, ...(props.eventProperties || {}) },
      segmentClient,
    }),
    [eventProperties, props.eventProperties, segmentClient]
  )

  return (
    <SegmentContext.Provider value={value}>
      {typeof props.children === 'function' ? props.children({ logEvent, instrument }) : props.children || null}
    </SegmentContext.Provider>
  )
}
