import { ApolloClient, ApolloLink, createHttpLink, defaultDataIdFromObject, InMemoryCache } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/nextjs'

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_BACKEND_GRAPHQL_ENDPOINT, // Server URL (must be absolute)
  fetch: fetch,
  headers: {
    'req-sent-from': 'customerWebapp',
  },
})
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    Sentry.captureException(new Error('GraphQL Request Error'), {
      extra: {
        errorAsString: graphQLErrors.map(
          ({ message, locations, path }) =>
            `Operation Name: ${
              operation?.operationName
            } -- [GraphQL Error]: Message: ${message}, Locations: ${locations}, Path: ${path} -- Operation Query: ${JSON.stringify(
              operation?.query
            )} -- Operation Variables ${JSON.stringify(operation?.variables)}`
        ),
      },
    })
  }
  if (networkError) {
    Sentry.captureException(new Error('GraphQL Network Error'), {
      extra: {
        errorAsString: `Operation Name: ${
          operation?.operationName
        } -- [Network error]: ${networkError} -- Operation Query: ${JSON.stringify(
          operation?.query
        )} -- Operation Variables ${JSON.stringify(operation?.variables)}`,
      },
    })
  }
})
const retryLink = new RetryLink()

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, retryLink, httpLink]),
  connectToDevTools: true,
  cache: new InMemoryCache({
    dataIdFromObject: (object) => defaultDataIdFromObject(object),
  }),
})

export default client
