import React, { useContext, useMemo } from 'react'
import { useMachine } from 'react-robot'
import { SendFunction, Service } from 'robot3'
import { leadMachine, machineContext } from '../components/LeadForm/leadMachine'

interface lmCurrent {
  current: typeof leadMachine['state'] & {
    context: machineContext
    service?: Service<typeof leadMachine>
  }
  send: SendFunction
}

const LeadMachineContext = React.createContext<lmCurrent>(undefined)
export const LeadMachineProvider = (props) => {
  const [current, send] = useMachine(leadMachine)
  const value = useMemo(() => ({ current, send }), [current, send])
  return <LeadMachineContext.Provider value={value}>{props.children}</LeadMachineContext.Provider>
}

export const useLeadMachine = () => {
  const context = useContext(LeadMachineContext)
  if (!context) {
    throw new Error('useLeadMachine must be used within a LeadMachineProvider')
  }
  return context
}
