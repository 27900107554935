import * as React from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'

declare type Props = {
  writeKey: string
  children: React.ReactNode
}

declare type SegmentContextType = {
  segmentClient?: AnalyticsBrowser
  getParentContext?(): SegmentContextType
  eventProperties?: any
}

export const SegmentContext = React.createContext<SegmentContextType>({
  eventProperties: {},
})

export function useSegmentContext() {
  return React.useContext(SegmentContext)
}

export function SegmentProvider(props: Props) {
  const { writeKey } = props

  // Memoize so it's only really called if the params change
  const segmentClient = React.useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey])
  // We need to init such that LogOnMount is happy
  return (
    <SegmentContext.Provider
      value={{
        segmentClient: segmentClient,
        eventProperties: {},
      }}
    >
      {props.children}
    </SegmentContext.Provider>
  )
}
