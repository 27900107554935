import * as React from 'react'
import { useSegment } from './Segment'

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  eventProperties?: object | Function
  eventType: string
  children?: React.ReactNode
}

export const SegmentLogOnMount: React.FunctionComponent<Props> = ({ eventType, children, eventProperties }: Props) => {
  const { logEvent } = useSegment()

  React.useEffect(() => {
    logEvent(eventType, eventProperties)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children || (null as any)
}
